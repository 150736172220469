import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '65, 65, 60',
		'primary-dark': '29, 29, 27',
		'accent': '242, 220, 142',
		'accent-plus': '0, 0, 0',
	},
});
